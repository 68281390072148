:root {
  --universal-border-radius: .425rem;
  --universal-padding: 1.8rem;
  --universal-margin: .5rem;
  --button-back-color: #e5e9f0;
  --button-fore-color: #2e3440;
  --button-border-color: transparent;
  --button-hover-back-color: #d8dee9;
  --button-hover-border-color: transparent;
}

* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Ubuntu, helvetica neue, Helvetica, sans-serif;
}

#main {
  padding: var(--universal-padding);
  border-radius: var(--universal-border-radius);
  background-color: #a3aab7;
  flex-flow: wrap;
  place-content: flex-start space-evenly;
  align-items: center;
  max-width: 50rem;
  min-height: 100%;
  margin: auto;
  display: flex;
}

.block {
  flex: 0 0 50%;
  margin-top: 1em;
}

.row {
  margin-bottom: var(--universal-margin);
}

#play {
  flex-basis: 30%;
  min-width: 13em;
}

#params {
  flex-grow: 1;
  min-width: 70%;
}

#help {
  flex-grow: 1;
}

input {
  width: 100%;
}

input:focus {
  outline: none;
}

input[type="checkbox"] {
  zoom: 1.5;
  width: unset;
  margin-bottom: calc(2 * var(--universal-margin));
  vertical-align: sub;
}

#play-button-text, #play-button-text span {
  padding-bottom: calc(.8 * var(--universal-padding));
  text-align: center;
  width: 100%;
  font-size: 8em;
  display: inline-block;
}

#play-button-text:hover, #play-button-text:focus {
  cursor: pointer;
}

output {
  font-size: 1.2em;
  font-weight: bold;
}
/*# sourceMappingURL=index.383adc26.css.map */
