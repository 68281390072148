:root {
    --universal-border-radius: 0.425rem;
    --universal-padding: 1.8rem;
    --universal-margin: 0.5rem;
    --button-back-color: #e5e9f0;
    --button-fore-color: #2e3440;
    --button-border-color: transparent;
    --button-hover-back-color: #d8dee9;
    --button-hover-border-color: transparent;
}

* {
    box-sizing: border-box;
}

html, body {
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Ubuntu, helvetica neue, Helvetica, sans-serif;
    margin: 0;
}


#main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: flex-start;
    align-items: center;

    min-height: 100%;
    max-width: 50rem;
    margin: auto;
    background-color: #a3aab7;
    padding: var(--universal-padding);
    border-radius: var(--universal-border-radius);
}

.block {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    margin-top: 1em;
}

.row {
    margin-bottom: var(--universal-margin);
}

#play {
    flex-basis: 30%;
    min-width: 13em;
}

#params {
    flex-grow: 1;
    min-width: 70%;
}

#help {
    flex-grow: 1;
}

input {
    width: 100%;
}

input:focus {
    outline: none;
}

input[type="checkbox"] {
    zoom: 1.5;
    width: unset;
    margin-bottom: calc(2.0 * var(--universal-margin));
    vertical-align: sub;
}

#play-button-text, #play-button-text span {
    display: inline-block;
    padding-bottom: calc(0.8 * var(--universal-padding));
    font-size: 8em;
    width: 100%;
    text-align: center;
}

#play-button-text:hover, #play-button-text:focus {
    cursor: pointer;
}

output {
    font-weight: bold;
    font-size: 1.2em;
}

